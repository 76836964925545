var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dataMigration-wrapper" },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                "label-position": "right",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "preChargeName", label: "配置名称：" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "160", placeholder: "请输入配置名称" },
                    model: {
                      value: _vm.formInline.preChargeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "preChargeName", $$v)
                      },
                      expression: "formInline.preChargeName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "operationId", label: "所属商户：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.type === "edit",
                        filterable: "",
                        size: "15",
                      },
                      model: {
                        value: _vm.formInline.operationId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "operationId",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.operationId",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "全部", value: "" } }),
                      _vm._l(_vm.tenantList, function (value) {
                        return _c("el-option", {
                          key: value.operationId,
                          attrs: {
                            label: value.operationName,
                            value: value.operationId,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "contentImtem" }, [
            _c("span", { staticClass: "tipicon" }),
            _c("b", { staticClass: "tiptitle" }, [_vm._v("预付费端口")]),
            _c(
              "div",
              { staticClass: "padding16" },
              [
                _c(
                  "el-checkbox-group",
                  {
                    model: {
                      value: _vm.formInline.preChargeDevice,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "preChargeDevice", $$v)
                      },
                      expression: "formInline.preChargeDevice",
                    },
                  },
                  [_c("el-checkbox", { attrs: { label: "PDA" } })],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "contentImtem" }, [
            _c("span", { staticClass: "tipicon" }),
            _c("b", { staticClass: "tiptitle" }, [_vm._v("收费规则")]),
            _c(
              "div",
              { staticClass: "padding16" },
              [
                _c(
                  "el-radio-group",
                  {
                    on: { change: _vm.changeLabel },
                    model: {
                      value: _vm.formInline.preChargeRuleType,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "preChargeRuleType", $$v)
                      },
                      expression: "formInline.preChargeRuleType",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "1" } }, [
                      _vm._v("按时计费"),
                    ]),
                    _c("el-radio", { attrs: { disabled: "", label: "2" } }, [
                      _vm._v("固定金额"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.formInline.preChargeRuleType
              ? _c("p", { staticClass: "tipdetail" }, [
                  _vm._v(" 配置PDA端按时间模式进行预收费，查看"),
                  _c(
                    "span",
                    {
                      staticClass: "blueColor",
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = true
                        },
                      },
                    },
                    [_vm._v("PDA端呈现效果图")]
                  ),
                  _vm._v("。 "),
                ])
              : _vm._e(),
          ]),
          _vm.formInline.preChargeRuleType == 2
            ? _c("div", { staticClass: "contentImtem" }, [
                _c("span", { staticClass: "tipicon" }),
                _c("b", { staticClass: "tiptitle" }, [
                  _vm._v("预付费金额配置"),
                ]),
                _c(
                  "div",
                  { staticClass: "padding16" },
                  [
                    _c("el-input", {
                      staticClass: "width30",
                      attrs: {
                        size: "small",
                        type: "number",
                        placeholder: "未配置",
                        maxlength: "5",
                        oninput:
                          "if(value.length>5){value=value.slice(0,5)};if(value <= 0){value=''}",
                      },
                      model: {
                        value: _vm.formInline.preChargeRuleContent.level1,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline.preChargeRuleContent,
                            "level1",
                            $$v
                          )
                        },
                        expression: "formInline.preChargeRuleContent.level1",
                      },
                    }),
                    _c("el-input", {
                      staticClass: "width30 padding020",
                      attrs: {
                        size: "small",
                        type: "number",
                        placeholder: "未配置",
                        maxlength: "5",
                        oninput:
                          "if(value.length>5){value=value.slice(0,5)};if(value <= 0){value=''}",
                      },
                      model: {
                        value: _vm.formInline.preChargeRuleContent.level2,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline.preChargeRuleContent,
                            "level2",
                            $$v
                          )
                        },
                        expression: "formInline.preChargeRuleContent.level2",
                      },
                    }),
                    _c("el-input", {
                      staticClass: "width30",
                      attrs: {
                        size: "small",
                        type: "number",
                        placeholder: "未配置",
                        maxlength: "5",
                        oninput:
                          "if(value.length>5){value=value.slice(0,5)};if(value <= 0){value=''}",
                      },
                      model: {
                        value: _vm.formInline.preChargeRuleContent.level3,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline.preChargeRuleContent,
                            "level3",
                            $$v
                          )
                        },
                        expression: "formInline.preChargeRuleContent.level3",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "padding16" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.formInline.preChargeRuleContent.custom,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline.preChargeRuleContent,
                              "custom",
                              $$v
                            )
                          },
                          expression: "formInline.preChargeRuleContent.custom",
                        },
                      },
                      [_vm._v("支持手动输入")]
                    ),
                  ],
                  1
                ),
              ])
            : _c(
                "div",
                { staticClass: "contentImtem" },
                [
                  _c("span", { staticClass: "tipicon" }),
                  _c("b", { staticClass: "tiptitle" }, [_vm._v("预付费提醒")]),
                  _c(
                    "div",
                    { staticClass: "padding16" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.formInline.preChargeNotice,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "preChargeNotice", $$v)
                            },
                            expression: "formInline.preChargeNotice",
                          },
                        },
                        [_vm._v("提醒")]
                      ),
                      _c("span", { staticClass: "tipwarn" }, [
                        _vm._v(
                          "预付时长耗尽前触发消息提醒到车主；【停车场】【车牌号】程序会根据订单信息自动匹配"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("el-input", {
                    staticStyle: { "padding-left": "16px", width: "80%" },
                    attrs: {
                      type: "textarea",
                      rows: 2,
                      maxlength: "140",
                      placeholder: "",
                    },
                    model: {
                      value: _vm.formInline.preChargeNoticeContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "preChargeNoticeContent", $$v)
                      },
                      expression: "formInline.preChargeNoticeContent",
                    },
                  }),
                ],
                1
              ),
          _c(
            "div",
            { staticClass: "save" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.back },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.dialogVisible,
            top: "50px",
            width: "375px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.formInline.preChargeRuleType == 1
            ? _c("div", { staticClass: "billingon" }, [
                _c("div", { staticClass: "billingonTop" }, [
                  _c("i", { staticClass: "el-icon-arrow-left elIconLeft" }),
                  _c("b", { staticClass: "title" }, [_vm._v("预收车费")]),
                ]),
                _c("div", { staticClass: "billContent" }, [
                  _c("div", { staticClass: "money" }, [
                    _c("b", [_vm._v("20")]),
                    _vm._v("元"),
                  ]),
                  _c(
                    "div",
                    [
                      _vm._v(" 停车时长："),
                      _c("el-input", {
                        staticStyle: { width: "185px" },
                        attrs: { placeholder: "请输入内容", disabled: "" },
                        model: {
                          value: _vm.input,
                          callback: function ($$v) {
                            _vm.input = $$v
                          },
                          expression: "input",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tipDetail" }, [
                    _vm._v("收费员可以通过下拉选择停车时长，系统自动"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "footer" },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("预收车费"),
                      ]),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.formInline.preChargeRuleType == 2
            ? _c("div", { staticClass: "billingon" }, [
                _c("div", { staticClass: "billingonTop" }, [
                  _c("i", { staticClass: "el-icon-arrow-left elIconLeft" }),
                  _c("b", { staticClass: "title" }, [_vm._v("预收车费")]),
                ]),
                _c("div", { staticClass: "billContent" }, [
                  _c(
                    "div",
                    {},
                    [
                      _vm._v(" 预付费： "),
                      _c("el-input", {
                        staticClass: "widthinput",
                        attrs: {
                          size: "small",
                          placeholder: "未配置",
                          disabled: "",
                        },
                        model: {
                          value: _vm.formInline.preChargeRuleContent.level1,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline.preChargeRuleContent,
                              "level1",
                              $$v
                            )
                          },
                          expression: "formInline.preChargeRuleContent.level1",
                        },
                      }),
                      _c("el-input", {
                        staticClass: "widthinput padding8",
                        attrs: {
                          size: "small",
                          placeholder: "未配置",
                          disabled: "",
                        },
                        model: {
                          value: _vm.formInline.preChargeRuleContent.level2,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline.preChargeRuleContent,
                              "level2",
                              $$v
                            )
                          },
                          expression: "formInline.preChargeRuleContent.level2",
                        },
                      }),
                      _c("el-input", {
                        staticClass: "widthinput",
                        attrs: {
                          size: "small",
                          placeholder: "未配置",
                          disabled: "",
                        },
                        model: {
                          value: _vm.formInline.preChargeRuleContent.level3,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline.preChargeRuleContent,
                              "level3",
                              $$v
                            )
                          },
                          expression: "formInline.preChargeRuleContent.level3",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.formInline.preChargeRuleContent.custom
                    ? _c(
                        "div",
                        [
                          _vm._v(" 其他金额："),
                          _c("el-input", {
                            staticStyle: { width: "150px", padding: "0 10px" },
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.input,
                              callback: function ($$v) {
                                _vm.input = $$v
                              },
                              expression: "input",
                            },
                          }),
                          _vm._v("元 "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "tipDetail" }, [
                    _vm._v("勾选【支持手动输入】后显示"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "footer" },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("预收车费"),
                      ]),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }