<template>
  <!-- 预付费配置-编辑-新增 -->
  <div class="dataMigration-wrapper">
    <!--主体内容-->
    <div class="content">
      <!-- <div class="topback">
        <el-button @click="back" size="medium" type="primary">返回</el-button>
      </div> -->

      <el-form
        :inline="true"
        label-position="right"
        :model="formInline"
        :rules="rules"
        ref="form"
        class="demo-form-inline"
      >
        <el-form-item prop="preChargeName" label="配置名称：">
          <el-input
            maxlength="160"
            v-model="formInline.preChargeName"
            placeholder="请输入配置名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="operationId" label="所属商户：">
          <el-select
            :disabled="type === 'edit'"
            v-model.trim="formInline.operationId"
            filterable
            size="15"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              :label="value.operationName"
              :value="value.operationId"
              :key="value.operationId"
              v-for="value in tenantList"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="contentImtem">
        <span class="tipicon"></span><b class="tiptitle">预付费端口</b>
        <div class="padding16">
          <el-checkbox-group v-model="formInline.preChargeDevice">
            <el-checkbox label="PDA"></el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="contentImtem">
        <span class="tipicon"></span><b class="tiptitle">收费规则</b>
        <div class="padding16">
          <el-radio-group @change="changeLabel" v-model="formInline.preChargeRuleType">
            <el-radio :label="'1'">按时计费</el-radio>
            <el-radio disabled :label="'2'">固定金额</el-radio>
          </el-radio-group>
        </div>
        <p class="tipdetail" v-if="formInline.preChargeRuleType">
          配置PDA端按时间模式进行预收费，查看<span class="blueColor" @click="dialogVisible = true"
            >PDA端呈现效果图</span
          >。
        </p>
      </div>
      <div class="contentImtem" v-if="formInline.preChargeRuleType == 2">
        <span class="tipicon"></span><b class="tiptitle">预付费金额配置</b>
        <div class="padding16">
          <el-input
            class="width30"
            size="small"
            type="number"
            v-model="formInline.preChargeRuleContent.level1"
            placeholder="未配置"
            maxlength="5"
            oninput="if(value.length>5){value=value.slice(0,5)};if(value <= 0){value=''}"
          ></el-input>
          <el-input
            class="width30 padding020"
            size="small"
            type="number"
            v-model="formInline.preChargeRuleContent.level2"
            placeholder="未配置"
            maxlength="5"
            oninput="if(value.length>5){value=value.slice(0,5)};if(value <= 0){value=''}"
          ></el-input>
          <el-input
            class="width30"
            size="small"
            type="number"
            v-model="formInline.preChargeRuleContent.level3"
            placeholder="未配置"
            maxlength="5"
            oninput="if(value.length>5){value=value.slice(0,5)};if(value <= 0){value=''}"
          ></el-input>
        </div>
        <div class="padding16">
          <el-checkbox v-model="formInline.preChargeRuleContent.custom">支持手动输入</el-checkbox>
        </div>
      </div>

      <div v-else class="contentImtem">
        <span class="tipicon"></span><b class="tiptitle">预付费提醒</b>
        <div class="padding16">
          <el-checkbox v-model="formInline.preChargeNotice">提醒</el-checkbox>
          <span class="tipwarn"
            >预付时长耗尽前触发消息提醒到车主；【停车场】【车牌号】程序会根据订单信息自动匹配</span
          >
        </div>
        <el-input
          style="padding-left: 16px; width: 80%"
          type="textarea"
          :rows="2"
          maxlength="140"
          placeholder=""
          v-model="formInline.preChargeNoticeContent"
        >
        </el-input>
      </div>

      <div class="save">
        <el-button @click="submit" size="medium" type="primary">{{ $t('button.preservation') }}</el-button>
        <el-button @click="back" size="medium" type="primary">取消</el-button>
      </div>
    </div>
    <!--按时计费 效果图 -->
    <el-dialog title="" :visible.sync="dialogVisible" top="50px" width="375px" center>
      <div class="billingon" v-if="formInline.preChargeRuleType == 1">
        <div class="billingonTop">
          <i class="el-icon-arrow-left elIconLeft"></i>
          <b class="title">预收车费</b>
        </div>
        <div class="billContent">
          <div class="money"><b>20</b>元</div>
          <div>
            停车时长：<el-input
              v-model="input"
              style="width: 185px"
              placeholder="请输入内容"
              disabled
            ></el-input>
          </div>
          <div class="tipDetail">收费员可以通过下拉选择停车时长，系统自动</div>
          <div class="footer">
            <el-button type="primary">预收车费</el-button>
          </div>
        </div>
      </div>
      <div class="billingon" v-if="formInline.preChargeRuleType == 2">
        <div class="billingonTop">
          <i class="el-icon-arrow-left elIconLeft"></i>
          <b class="title">预收车费</b>
        </div>
        <div class="billContent">
          <div class="">
            预付费：
            <el-input
              class="widthinput"
              size="small"
              v-model="formInline.preChargeRuleContent.level1"
              placeholder="未配置"
              disabled
            ></el-input>
            <el-input
              class="widthinput padding8"
              size="small"
              v-model="formInline.preChargeRuleContent.level2"
              placeholder="未配置"
              disabled
            ></el-input>
            <el-input
              class="widthinput"
              size="small"
              v-model="formInline.preChargeRuleContent.level3"
              placeholder="未配置"
              disabled
            ></el-input>
          </div>
          <div v-if="formInline.preChargeRuleContent.custom">
            其他金额：<el-input
              v-model="input"
              style="width: 150px; padding: 0 10px"
              placeholder="请输入"
            ></el-input
            >元
          </div>
          <div class="tipDetail">勾选【支持手动输入】后显示</div>
          <div class="footer">
            <el-button type="primary">预收车费</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "addeditPrepaidConfig",
  data() {
    return {
      input: "10",
      preChargeSettingId: "",
      type: "add",
      tenantList: [],
      dialogVisible: false,
      formInline: {
        preChargeName: "", // 预付费配置名称
        operationId: "", // 运营商ID
        preChargeDevice: "", // 预付端口 1 = PDA
        preChargeRuleType: "1", // 收费规则 1 = 按时2 = 按钱
        preChargeRuleContent: {
          level1: "25",
          level2: "",
          level3: "",
          custom: "",
        }, // 计费规则JSON内容
        preChargeNotice: "", // 是否提醒 1 = 提醒 0 = 不提醒
        preChargeNoticeContent:
          "亲爱的车主，您停放在【停车场】的车辆【车牌号】 预付的费用即将耗尽，请关注，祝您一切顺利。", // 提醒内容,

        custom: "", // 支持手工输入  1支持  0不支持
      },
      rules: {
        preChargeName: [
          {
            required: true,
            message: "请选择配置名称",
            trigger: "change",
          },
        ],
        operationId: [
          {
            required: true,
            message: "请选择所属商户",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    // this.getTenantList();
  },
  mounted() {
    console.log(this.$route, "route");
    this.type = this.$route.query.type ? this.$route.query.type : this.type;
    this.preChargeSettingId = this.$route.query.preChargeSettingId
      ? this.$route.query.preChargeSettingId
      : "";
    // 编辑
    if (this.type === "edit") {
      console.log(this.$route.query, "this.$route.query");
      this.searchData();
      this.getAllTenantList();
    } else {
      this.getTenantList();
    }
  },
  methods: {
    changeLabel() {
      this.$forceUpdate();
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.formInline, "this.formInline");
          this.addoredit();
        }
      });
    },
    addoredit() {
      if (this.formInline.preChargeRuleType == 2) {
        this.formInline.preChargeNoticeContent = "";
        this.formInline.preChargeNotice = false;
        if (
          this.formInline.preChargeRuleContent.custom ||
          this.formInline.preChargeRuleContent.level1 ||
          this.formInline.preChargeRuleContent.level2 ||
          this.formInline.preChargeRuleContent.level3 ||
          this.formInline.preChargeRuleContent.custom
        ) {
        } else {
          this.$message({
            message: "请至少配置一个金额项供PDA端使用",
            type: "warning",
          });
          return;
        }
      }
      let url =
        this.type == "add" ? "/acb/2.0/preChargeSetting/save" : "/acb/2.0/preChargeSetting/modify";
      let type = this.type == "add" ? "post" : "post";
      let preChargeRuleContent = JSON.parse(JSON.stringify(this.formInline.preChargeRuleContent));
      if (this.formInline.preChargeRuleType == "2") {
        preChargeRuleContent.custom = preChargeRuleContent.custom ? "1" : "0";
        preChargeRuleContent.level1 = preChargeRuleContent.level1
          ? preChargeRuleContent.level1 * 100
          : "";
        preChargeRuleContent.level2 = preChargeRuleContent.level2
          ? preChargeRuleContent.level2 * 100
          : "";
        preChargeRuleContent.level3 = preChargeRuleContent.level3
          ? preChargeRuleContent.level3 * 100
          : "";
      } else {
        preChargeRuleContent = null;
      }

      this.$axios[type](url, {
        data: {
          preChargeSettingId: this.type == "add" ? null : this.preChargeSettingId,
          preChargeName: this.formInline.preChargeName,
          operationId: this.formInline.operationId,
          preChargeDevice: this.formInline.preChargeDevice ? "1" : "",
          preChargeRuleType: this.formInline.preChargeRuleType,
          preChargeRuleContent: JSON.stringify(preChargeRuleContent),
          preChargeNotice: this.formInline.preChargeNotice ? "1" : "0",
          preChargeNoticeContent: this.formInline.preChargeNoticeContent,
        },
      }).then((res) => {
        if (res.state == 0) {
          this.$message({
            message: "提交成功！",
            type: "success",
          });
          this.$router.go(-1);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },

    //
    search(preChargeSettingId) {
      this.$axios
        .get("/acb/2.0/preChargeSetting/getById", {
          data: {
            preChargeSettingId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.tenantList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 返回上一页
    back() {
      this.$router.go(-1);
    },
    /* 商户名称的下拉框-筛选后的值 */
    getTenantList() {
      this.$axios.get("/acb/2.0/preChargeSetting/getPreChargeSettingOperation").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    /* 商户名称的下拉框-全部 */
    getAllTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 查询
    searchData() {
      this.$axios
        .get("/acb/2.0/preChargeSetting/getById", {
          data: {
            preChargeSettingId: this.preChargeSettingId,
          },
        })
        .then((res) => {
          if (res.state === 0) {
            this.formInline = res.value;
            // this.formInline.preChargeRuleType = res.value.preChargeRuleType
            // this.$set(this.formInline, 'preChargeRuleType', res.value.preChargeRuleType)
            this.formInline.preChargeDevice = this.formInline.preChargeDevice == "1";
            this.formInline.preChargeNotice = this.formInline.preChargeNotice == "1";
            this.formInline.preChargeRuleContent = JSON.parse(res.value.preChargeRuleContent);
            if (this.formInline.preChargeRuleContent) {
              this.formInline.preChargeRuleContent.level1 = res.value.preChargeRuleContent.level1
                ? res.value.preChargeRuleContent.level1 / 100
                : "";
              this.formInline.preChargeRuleContent.level2 = res.value.preChargeRuleContent.level2
                ? res.value.preChargeRuleContent.level2 / 100
                : "";
              this.formInline.preChargeRuleContent.level3 = res.value.preChargeRuleContent.level3
                ? res.value.preChargeRuleContent.level3 / 100
                : "";
              this.formInline.preChargeRuleContent.custom =
                this.formInline.preChargeRuleContent.custom == "1";
            } else {
              this.formInline.preChargeRuleContent = {
                level1: "",
                level2: "",
                level3: "",
                custom: "",
              };
            }

            console.log(this.formInline, "this.formInline");
          }
        });
    },
  },
};
</script>
<style lang="stylus">
.dataMigration-wrapper {
  background: #F4F7F9;
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.padding16 {
  padding: 16px;
}
.padding8 {
  padding: 8px;
}
.width30 {
  width: 30%;
}
.widthinput{
  width: 60px;
  height: 40px;
}
.content {
  padding: 0 24px;

  .padding020 {
    padding: 0 20px;
  }

  .topback {
    margin: 16px 0;
    text-align: right;
  }

  .demo-form-inline {
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 16px;
    padding: 16px 55px 0;
  }

  .save {
    padding-top: 16px;
    text-align: center;
  }

  .contentImtem {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;

    .tipicon {
      width: 4px;
      height: 15px;
      background: #0C66FF;
      display: inline-block;
    }

    .tiptitle {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2F2F51;
      line-height: 20px;
      padding-left: 16px;
    }

    .tipdetail {
      padding-left: 16px;
      font-size: 12px;

      .blueColor {
        color: rgba(12, 102, 255, 1);
        cursor: pointer;
      }
    }

    .tipwarn {
      padding-left: 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 16px;
    }
  }
}

.billingon {
  width: 100%;
  height: 667px;
  background: #F5F6FA;
  border: 1px solid #ccc;
  position: relative;

  .billingonTop {
    text-align: center;
    background: #FFFFFF;

    .elIconLeft {
      font-size: 20px;
      // float: left;
      line-height: 50px;
      font-weight: 700;
      position: absolute;
      top: 0px;
      left: 20px;
    }

    .title {
      font-size: 19px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 700;
      color: #000000;
      line-height: 53px;
    }
  }

  .tipdetail {
    font-size: 12px;
    padding: 10px 0 80px;
  }

  .billContent {
    background: #FFFFFF;
    box-shadow: 0px 6px 17px 0px rgba(223, 223, 223, 0.5);
    border-radius: 17px;
    height: 300px;
    margin: 15px;
    padding: 19px;

    .money {
      text-align: center;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #3980F8;
      line-height: 75px;

      >b {
        font-size: 30px;
      }
    }

    .tipDetail {
      padding: 10px 0;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FF3E05;
      line-height: 20px;
    }

    .footer {
      text-align: center;
      padding-top: 30px;
    }
  }
}
</style>
